<template>
  <v-list-item-content>
    <v-list-item-title
      >{{ message.channelName }} -
      {{ message.createdDateTime | dateFormat }}</v-list-item-title
    >
    <v-list-item-subtitle>
      <user-avatar :id="message.createdByUserId" /> -
      {{ message.content | truncate(100, '...') }}</v-list-item-subtitle
    >
  </v-list-item-content>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'

import xss from 'xss'

export default {
  components: {
    userAvatar: () => import('@/components/member/avatar-icon.vue')
  },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['userId']),
    prettyContent() {
      const markdown = marked.parse(this.message.content)
      return xss(markdown)
    }
  }
}
</script>
